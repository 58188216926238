import React, { Component } from 'react';
import AOS from 'aos';

import Layout from '../components/layout/layout';
import SEO from '../components/seo/seo';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import { parseRef } from '../services/local-storage';

class NotFoundPage extends Component {
  componentDidMount() {
    this.aos = AOS;
    this.aos.init({
      once: true,
      duration: 1000,
    });

    parseRef(this.props.location);
  }

  componentDidUpdate() {
    this.aos.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="404: Not found" description="Page not found" />
        <Header />
        <div
          style={{
            margin: '6rem 0',
            textAlign: 'center',
          }}
        >
          <h1>NOT FOUND</h1>
          <p>You just hit a route that doesn&#39;t exist... </p>
          <p>
            <a
              href="/"
              style={{
                textTransform: 'uppercase',
                background: '#f61c0d',
                color: '#fff',
                padding: '1rem 2rem',
                borderRadius: '0.8rem',
                textDecoration: 'none',
              }}
            >
              ..back to the main page
            </a>
          </p>
        </div>
        <Footer />
      </Layout>
    );
  }
}

export default NotFoundPage;
